import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "./breadcrumbs";
import { getUserInfo, logoutService, removeToken } from "../../services/authService";
import LayoutContext from "../../services/context/layout/context";


export const HeaderComponent = () => {

    const { breadcrumbContext } = useContext(LayoutContext);
    const { setGlobalSearch } = useContext(LayoutContext);

    const [showBar, setShowBar] = useState(false)
    const [displayBar, setDisplayBar] = useState(false)

    const nav = useNavigate();
    const location = useLocation()

    const [searchTerm, setSearchTerm] = useState('');
    const [searchPlaceholder, setSearchPlaceholder] = useState('Search')
    const debounceDelay = 1000;

    const [userInfo, setUserInfo] = useState({
        username: "JoyLeaf",
        email: ""
    });

    useEffect(() => {
        const _userInfo = getUserInfo();
        if (_userInfo && _userInfo.username) {
            setUserInfo(_userInfo)
        }
    }, [])

    const logout = async () => {
        await logoutService();
        removeToken();
        window.location.href = "/#/login";
    }

    const getUserNameProfile = (username) => {
        let splitName = username.split(' ');
        if (splitName.length > 1) {
            return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`
        }
        else return splitName[0].charAt(0)
    }

    useEffect(() => {
        setSearchPlaceholder('Search')
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length == 2) {
            if (pathArray[1] === "products") {
                setDisplayBar(false)
                setSearchPlaceholder('Search by Product Name, Brand or Category')
            }
            else {
                setDisplayBar(true)
            }
        }
        else {
            setDisplayBar(true)
        }
    }, [location])

    useEffect(() => {
        if (breadcrumbContext.length && breadcrumbContext[0].hasSubMenu) {
            setShowBar(breadcrumbContext[0].hasSubMenu)
        }
        else {
            setShowBar(false)
        }
    }, [breadcrumbContext])

    useEffect(() => {
        const timer = setTimeout(() => {
            setGlobalSearch(searchTerm)
        }, debounceDelay);

        return () => {
            clearTimeout(timer); // Clear the timer when the component unmounts or when searchTerm changes
        };
    }, [searchTerm]);

    const handleSearch = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setGlobalSearch(searchTerm)
        }
    }

    const logoutContent = () => {
        return (
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" data-bs-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary primary-link" data-bs-dismiss="modal" onClick={() => logout()}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar fixed-top mb-0 shadow vistaHeaderMargin vistaCollapsedHeaderMargin collapsed-header-margin-sub-menu" id="blHeader">

                {/* <!-- Sidebar Toggle (Topbar) --> */}
                {/* <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-0">
                    <i className="fa fa-window-maximize"></i>
                </button> */}
                {
                    showBar && displayBar ? <button id="togglersubmenu" className="btn btn-link rounded-circle mr-0 text-muted">
                        <i className="fa fa-bars"></i>
                    </button> : null
                }
                {/* <div><h6 className="font-weight-bold text-muted m-0">{componentName}</h6></div> */}
                <div className={showBar ? 'ml-2' : 'ml-3'}>
                    <BreadcrumbComponent></BreadcrumbComponent>
                </div>


                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav ml-auto">

                    {/* <!-- Nav Item - Search Dropdown (Visible Only XS/Mobile) --> */}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <button className="nav-link dropdown-toggle" id="searchDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-search fa-fw"></i>
                        </button>
                        {/* <!-- Dropdown - Messages --> */}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fa fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/* <!-- Topbar Search --> */}
                    <li className="nav-item dropdown no-arrow mx-1">
                        <button className="nav-link dropdown-toggle" id="">
                            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group align-items-center search-bar">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    <input type="text" className="form-control border-0 shadow-none bg-grey search" value={searchTerm} onChange={handleSearch} onKeyDown={handleKeySearch} placeholder={searchPlaceholder} aria-label="Search" aria-describedby="basic-addon2" />
                                    {searchTerm && (
                                        <i className="fa fa-times ml-1" aria-hidden="true" onClick={() => setSearchTerm('')}></i>
                                    )}
                                </div>
                            </form>
                        </button>
                    </li>

                    {/* <!-- Nav Item - Alerts --> */}
                    <li className="nav-item dropdown no-arrow mx-1 d-none">
                        <button className="nav-link dropdown-toggle" id="alertsDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-bell" aria-hidden="true"></i>
                            {/* <!-- Counter - Alerts --> */}
                            <span className="badge badge-danger badge-counter">3+</span>
                        </button>
                        {/* <!-- Dropdown - Alerts --> */}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fa fa-file-code-o text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fa fa-usd text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </button>
                            <button className="dropdown-item d-flex align-items-center" >
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fa fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </button>
                            <button className="dropdown-item text-center small text-gray-500" >Show All Alerts</button>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/* <!-- Nav Item - User Information panel --> */}
                    <li className="nav-item dropdown no-arrow">
                        <button className="nav-link dropdown-toggle" id="userDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userInfo.username}</span>
                            <span className="bl-profile-circle text-uppercase">{getUserNameProfile(userInfo.username)}</span>
                            {/* <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" /> */}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            {/* <button className="dropdown-item" >
                                <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                                Profile
                            </button>
                            <button className="dropdown-item" >
                                <i className="fa fa-cogs fa-sm mr-2 text-gray-400"></i>
                                Settings
                            </button>
                            <button className="dropdown-item" >
                                <i className="fa fa-list fa-sm mr-2 text-gray-400"></i>
                                Activity Log
                            </button> */}
                            {/* <div className="dropdown-divider"></div> */}
                            <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#logoutModal">
                                <i className="fa fa-sign-out fa-sm mr-2 text-gray-400"></i>
                                Logout
                            </button>
                        </div>
                    </li>

                </ul>

            </nav>
            {logoutContent()}
        </>
    )
}