import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GridViewComponent } from "./gridView";
import { TableViewComponent } from "./tableView";
import { getReportCategoryListService, getReportListService } from "../../services/analyticsService";
import LayoutContext from "../../services/context/layout/context";
import { capitalizeFirstLetter } from "../../utils/common";


export const AnalyticsReportListComponent = () => {
    window.scrollTo(0, 0);

    const { setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch } = useContext(LayoutContext);

    const [viewType, setViewType] = useState('GRID');
    const [reports, setReports] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type'
    ])
    const [page, setPage] = useState(1)
    const location = useLocation();

    const getReportType = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return pathArray[3]
            }
            return pathArray[2]
        }
        return ''
    }

    useEffect(() => {
        let reportCatName = getReportType();
        console.log("reportCatName ===>>>", reportCatName);
        setBreadcrumbContext(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: `/analytics/reports/${reportCatName}`, menuName: capitalizeFirstLetter(reportCatName), isActive: false, hasSubMenu: true }
              ]
        )
    }, [location])

    useEffect(() => {

    }, [globalSearch])

    useEffect(() => {
        setViewType('GRID')
    }, [])

    // useEffect(() => {
    //     let report = [];
    //     let reportCatName = getReportType();
    //     if (reportCatName === 'shared') {
    //         reportsJson.map((r) => {
    //             r.sharedWith.filter(s => {
    //                 if (s === 'Apurva Pandey') {
    //                     report.push(r)
    //                 }
    //             })
    //         });
    //         setReports(report)
    //     }
    //     else {
    //         report = reportsJson.filter(r => r.catSlug === reportCatName);
    //         setReports(report);
    //     }
    // }, [location, reportsJson])

    useEffect(() => {
        let reportCatName = getReportType();
        getAnalyticsReportCategory(reportCatName)
    }, [location])

    const getAnalyticsReportCategory = async(catSlug) => {
        const resp = await getReportCategoryListService();
        if(resp && resp.success){
            const categoryItems = resp.data.length && resp.data.find(cat => cat.menuType === "CATEGORIES").list;
            const categoryId = categoryItems.find(c=>c.slug === catSlug).reportCategoryId;
            getReportList(categoryId)
        }
    }

    const getReportList = async(categoryId) => {
        const resp = await getReportListService(categoryId, page);
        if(resp && resp.success){
            setReports(resp.data)
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h6 className="mb-0">List of available reports</h6>
                    </div>
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button className={`btn btn-sm ${viewType === 'GRID' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                        <button className={`btn btn-sm ${viewType === 'LIST' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        viewType === 'GRID' ?
                            <GridViewComponent reports={reports}></GridViewComponent> : <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent>
                    }

                </section>
            </div>
        </div>
    )
}