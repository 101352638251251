import { toast } from "react-toastify";

const success = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        draggable:true
    })
}

const error = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        draggable:true
    })
}

const info = (message) => {
    toast.info(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        draggable:true
    })
}

const warning = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        draggable:true
    })
}

let NotifyService = {
    success, error, info, warning
}

export default NotifyService;