import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { withKnobs, boolean, number, text } from '@storybook/addon-knobs';

export const CarouselComponent = ({ images, serverImage, height, showThumbs=false, dynamicHeight=false }) => {

    const tooglesGroupId = 'Toggles';
    const valuesGroupId = 'Values';
    const mainGroupId = 'Main';

    
    const getConfigurableProps = () => ({
        showArrows: boolean('showArrows', true, tooglesGroupId),
        showStatus: boolean('showStatus', false, tooglesGroupId),
        showIndicators: boolean('showIndicators', true, tooglesGroupId),
        infiniteLoop: boolean('infiniteLoop', true, tooglesGroupId),
        showThumbs: boolean('showThumbs', false, tooglesGroupId),
        useKeyboardArrows: boolean('useKeyboardArrows', true, tooglesGroupId),
        autoPlay: boolean('autoPlay', false, tooglesGroupId),
        stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
        swipeable: boolean('swipeable', true, tooglesGroupId),
        dynamicHeight: boolean('dynamicHeight', false, tooglesGroupId),
        emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
        autoFocus: boolean('autoFocus', false, tooglesGroupId),
        thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
        selectedItem: number('selectedItem', 0, {}, valuesGroupId),
        interval: number('interval', 2000, {}, valuesGroupId),
        transitionTime: number('transitionTime', 500, {}, valuesGroupId),
        swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
        ariaLabel: text('ariaLabel', undefined),
    });

    useEffect(()=>{
        getConfigurableProps()
    }, [showThumbs, dynamicHeight])

    return (
        <Carousel
        // infiniteLoop
        // centerMode
        // centerSlidePercentage={number('centerSlidePercentage', 80, {}, mainGroupId)}
         {...getConfigurableProps()}>
            {
                images.map((img, i) => {
                    let prdImage = serverImage ? `${img.blobUrl}` : img;
                    return (
                        <div key={`carousel-img-${i}`}>
                            <img src={prdImage} alt="carousel-image" className="w-50" height={height} />
                        </div>
                    )
                })
            }
        </Carousel>
    )
}