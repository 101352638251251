import axios from "axios";
import { getToken, removeToken } from "./authService";
const apiKey = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: apiKey
})

instance.interceptors.request.use((config)=>{
    config.headers['Authorization'] = `${getToken()}`;
    return config;
}, (err)=>{
    return Promise.reject(err);
})

instance.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    console.log("error inspect ===>", err);
    if(!err.response){
        window.location.href='/#/system-down'
    }
    else if (err.response.status === 401) {
        removeToken()
        window.location.href = "/";
    }
    // else if(err.response.status === 500){
    //     window.location.href = "/#/error";
    // }
     
    return Promise.reject(err.response);
})

const reports = "/report";


export const getReportCategoryListService = async() => {
    try {
        return instance.get(`${reports}/category`)
    }
    catch(e){
        return e;
    }
}

export const getPinnedReportListService = async() => {
    try {
        return instance.get(`${reports}/pinned`)
    }
    catch(e){
        return e;
    }
}

export const getRecentReportListService = async(categoryId, page) => {
    try {
        return instance.get(`${reports}/list/${categoryId}/${page}`)
    }
    catch(e){
        return e;
    }
}

export const getReportListService = async(categoryId, page) => {
    try {
        return instance.get(`${reports}/${categoryId}/${page}`)
    }
    catch(e){
        return e;
    }
}

export const getReportInfoService = async(reportId) => {
    try {
        return instance.get(`${reports}/${reportId}`)
    }
    catch(e){
        return e;
    }
}

export const getReportEmbedTokenService = async(groupId, reportId) => {
    try {
        return instance.get(`${reports}/fetch/token/${groupId}/report/${reportId}`)
    }
    catch(e){
        return e;
    }
}