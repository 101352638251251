import React from "react";
import warningImg from "./../../assets/img/warning.png";

const UnknownErrorComponent = () => {


    return (
        <div id="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center error-page">
                        <img src={warningImg} alt='warning-alt' />
                        <h2 className="text-danger">Sorry an error has occured</h2>
                        <p>Unknown error occured while processing your request</p>
                        {/* <a href="/home" className="pb-3"><h5>Click here to return to the home page</h5></a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnknownErrorComponent;