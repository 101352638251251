import React, { useContext, useEffect, useState } from "react";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import buttonLabel from "./../../utils/property-file/buttons-label.json";
import overviewImg from "./../../assets/img/report-brand.jpg";
import appUpdatesJson from "./../../data/Analytics/appUpdates.json";
import { TableViewComponent } from "./tableView";
import { getRecentReportListService, getReportCategoryListService } from "../../services/analyticsService";
import LayoutContext from "../../services/context/layout/context";

export const AnalyticsOverviewComponent = () => {
    window.scrollTo(0, 0);

    const { setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch } = useContext(LayoutContext);

    const [updateList] = useState(appUpdatesJson);
    const [reports, setReports] = useState([]);
    const [filter, setFilter] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type'
    ])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        LATEST_UPDATE: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: "",
        TIPS: ""
    });
    const [page, setPage] = useState(1)

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "ANALYTICS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setBreadcrumbContext(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
              ]
        )
    }, [])

    useEffect(() => {

    }, [globalSearch])

    useEffect(()=>{
        getRecentReportList()
        getAnalyticsReportCategory()
    }, [])

    const getAnalyticsReportCategory = async() => {
        const resp = await getReportCategoryListService();
        if(resp && resp.success){
            let filters = [
                {
                    val: '0', name: 'All', isActive: true
                }
            ];
            const categoryItems = resp.data.length && resp.data.find(cat => cat.menuType === "CATEGORIES").list;
            categoryItems.map(r => {
                filters.push({
                    val: r.reportCategoryId, name: r.name, isActive: false
                })
            });
            setFilter(filters)
        }
    }

    const getRecentReportList = async() => {
        let filterItem = filter.find(f=>f.isActive);
        let categoryId = filterItem ? filterItem.val : '0';
        const resp = await getRecentReportListService(categoryId, page);
        if(resp && resp.success){
            setReports(resp.data)
        }
    }

    const filterReportList = (indx) => {
        let filterItem = filter.map(f=>{
            f.isActive = false;
            return f;
        })
        filterItem[indx].isActive = true;
        setFilter(filterItem);
        setPage(1);
        getRecentReportList();
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between">
                                    <div className="align-self-center mr-16">
                                        <h5>{labels.WELCOME}</h5>
                                        <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    </div>
                                    <div className="al">
                                        <img className="bl-overview-img" src={overviewImg} alt="overview-Img" />
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent p-0">
                                    <button className="btn btn-link"><small>{labels.TIPS}</small></button>
                                </div>
                            </div>
                        </section>

                        <section className="mb-4">
                            <h6 className="mb-3">{labels.GUIDE}</h6>
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <h6 className="card-title">{labels.ENTITY.TITLE_1}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_1}.</p>
                                        <button className="btn btn-secondary btn-sm">{buttonLabel.VIEW_NOW}</button>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <h6 className="card-title">{labels.ENTITY.TITLE_2}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_2}.</p>
                                        <button className="btn btn-secondary btn-sm">{buttonLabel.COMING_SOON}</button>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <h6 className="card-title">{labels.ENTITY.TITLE_3}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_3}</p>
                                        <button className="btn btn-secondary btn-sm">{buttonLabel.COMING_SOON}</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <h6 className="mb-3">{'Recently Viewed Reports'}</h6>
                                            <div className="d-flex pb-2">
                                                {
                                                    filter.map((f, i) => {
                                                        return (
                                                            <span key={`analytics-overview-filter-${i + 1}`} className={`badge bl-filter-chip cp ${f.isActive ? 'active' : ''}`} onClick={()=>filterReportList(i)}>{f.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* <div className="col py-3 mt-n3 d-md-none d-xs-none d-sm-none d-xl-block d-lg-block">
                        <AnalyticsOverviewSidePanelComponent list={updateList} activityTitle={labels.YOUR_ACTIVITY} updatesTitle={labels.LATEST_UPDATE}></AnalyticsOverviewSidePanelComponent>
                    </div> */}

                </div>
            </div>

        </div>
    )
}
