import React, { useContext, useEffect, useState } from "react";
import { TableViewComponent } from "./tableView";
import reportsJson from "./../../data/Analytics/reports.json";
import LayoutContext from "../../services/context/layout/context";


export const AnalyticsCatalogComponent = () => {
    window.scrollTo(0, 0);

    const { breadcrumbContext, setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch, setGlobalSearch } = useContext(LayoutContext);

    const [viewType, setViewType] = useState('LIST');
    const [reportList, setReportList] = useState([]);
    const [groupedReportList, setGroupedReportList] = useState([]);
    const [reportColumn] = useState([
        'Description', 'Report Type', 'Action'
    ]);
    const [reportType] = useState([
        { val: 'PowerBI', name: 'PowerBI' },
        { val: 'Tableau', name: 'Tableau' },
        { val: 'Domo', name: 'Domo' }
    ])
    const [filter, setFilter] = useState([
        { val: 'ALL', name: 'All', isActive: true },
        { val: 'PowerBI', name: 'PowerBI', isActive: false },
        { val: 'Tableau', name: 'Tableau', isActive: false },
        { val: 'Domo', name: 'Domo', isActive: false }
    ]);
    useEffect(() => {
        setBreadcrumbContext(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/catalog', menuName: 'Report Catalog', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    useEffect(() => {

    }, [globalSearch])

    useEffect(() => {
        setViewType('LIST');
        setReportList(reportsJson)
    }, [reportsJson])

    useEffect(() => {
        let items = getListGroupByCategory(reportList, 'reportCategory');
        let list = Object.keys(items).map((key) => {
            return { category: key, list: items[key] }
        });
        setGroupedReportList(list);
    }, [reportList])

    const getListGroupByCategory = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h6 className="mb-0">Report List</h6>
                    </div>
                    <div className="d-none">
                        <button className="btn btn-primary btn-sm mr-2" onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm" onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="pb-3">
                        <div className="col-xl-12 col-md-12 d-flex justify-content-between">
                            <div className="d-flex py-1">
                                {
                                    filter.map((f, i) => {
                                        return (
                                            <span key={`analytics-catalog-filter-${i + 1}`} className={`badge bl-filter-chip ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                        )
                                    })
                                }
                            </div>
                            <div className="form-group w-25 mb-0 align-self-center">
                                <input type="text" className="form-control form-control-sm" id="blcomshst" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        {
                            groupedReportList.map((item, i) => {
                                return (
                                    <div key={`catalog-accordion-${i + 1}`} className="accordion-item mb-3">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="accordion-header mb-0" id="companyinfo">
                                                    {item.category}
                                                    <span className="bl-accordion-icon" data-bs-toggle="collapse" data-bs-target={`#catalogcatview${i + 1}`} aria-expanded="false" aria-controls="blcompanyinfo">
                                                        <i className="fa fa-angle-down accordion-button p-0 bg-transparent" ></i>
                                                    </span>
                                                </h6>
                                            </div>
                                            <div className="card-body p-2 bl-catalog-category-view">
                                                <div id={`catalogcatview${i + 1}`} className="collapse show">
                                                    <TableViewComponent className="bl-catalog-category-view" data={item.list} reportColumn={reportColumn} catalog={true}></TableViewComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </div>
        </div>
    )


}