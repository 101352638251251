import React, { useContext, useEffect, useState } from "react";
import { GridViewComponent } from "../gridView";
import { TableViewComponent } from "../tableView";
import reportsJson from "./../../../data/Analytics/reports.json";
import LayoutContext from "../../../services/context/layout/context";



export const AnalyticsManageReportComponent = () => {
    window.scrollTo(0, 0);

    const { breadcrumbContext, setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch, setGlobalSearch } = useContext(LayoutContext);

    const [viewType, setViewType] = useState('LIST');
    const [reportList, setReportList] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type', 'User Groups'
    ])

    useEffect(() => {
        setBreadcrumbContext(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/manage-report', menuName: 'Manage Reports', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    useEffect(() => {

    }, [globalSearch])

    useEffect(() => {
        setViewType('LIST');
        setReportList(reportsJson)
    }, [])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h6>Published Reports</h6>
                    </div>
                    <div>
                        <button className="btn btn-secondary btn-sm mr-2" title="Publish New Report"><i className="fa fa-fw fa-plus" aria-hidden="true"></i></button>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button className="btn btn-secondary btn-sm" onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                            <button className="btn btn-secondary btn-sm" onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        viewType === 'GRID' ?
                            <GridViewComponent reports={reportList}></GridViewComponent> : <TableViewComponent data={reportList} reportColumn={reportColumn} pinRemove={true}></TableViewComponent>
                    }

                </section>
            </div>
        </div>
    )
}