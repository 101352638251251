import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import reportPlaceholder from "./../../assets/img/report-preview.png"
import { useNavigate } from "react-router-dom";

export const TableViewComponent = ({ data, reportColumn, catalog, pinRemove }) => {

    const nav = useNavigate()
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setNodes(data);
    }, [data]);

    const getReportTime = (reportdate) => {
        if (reportdate) {
            let now = moment();
            let reportTime = moment(reportdate);            
            let monthDiff = now.diff(reportTime, 'months');
            if(monthDiff === 0){
                let dateDiff = now.diff(reportTime, 'days');
                if(dateDiff === 0){
                    let hoursDiff = now.diff(reportTime, 'hours');
                    if(hoursDiff === 0){
                        let mintDiff = now.diff(reportTime, 'minutes');
                        if(mintDiff === 0){
                            return 'Updated now'
                        }
                        else return `Updated ${mintDiff} mins ago`
                    }
                    else return `Updated ${hoursDiff} hours ago`
                }
                else if(dateDiff === 1) {
                    let dateCalendar = reportTime.calendar({
                        sameDay: '[Today at] hh:mm A',
                        nextDay: '[Tomorrow]',
                        nextWeek: 'dddd',
                        lastDay: '[Yesterday at] hh:mm A',
                        lastWeek: '[Last] dddd',
                        sameElse: 'MMM DD, YYYY'
                    })
                    return `Updated ${dateCalendar}`
                }
                else return `Updated ${dateDiff} days ago`
            }
            else return `Updated ${monthDiff} months ago`
        }
    }

    const reportTypeBackground = (type) => {
        if(type){
            let reportType = type.toLowerCase();
            if(reportType === 'powerbi'){
                return 'badge-warning'
            }
            else if(reportType === 'tableau'){
                return 'badge-primary'
            }
            else if(reportType === 'domo'){
                return 'badge-info'
            }
            else {
                return 'badge-secondary'
            }
        }
        else {
            return 'badge-light'
        }
    }

    const arrangeColumnValue = (val, name) => {
        let report = val;
        if (name === 'REPORT') {
            return (
                <div className="d-flex align-items-center">
                    {
                       pinRemove ? '' : report.isPinned ? (<i className={`fa ${catalog ? 'fa-unlock' : 'fa-thumb-tack'} mr-2`} aria-hidden="true"></i>) : (<i className={`fa ${catalog ? 'fa-lock' : 'fa-thumb-tack text-gray'} mr-2`} aria-hidden="true"></i>)
                    }

                    <img src={reportPlaceholder} alt="" className="bl-report-thumbnail" />
                    <div className="pl-2">
                        <p className="mb-0 text-nowrap"><b>{report.displayName}</b></p>
                        <p className="text-muted font-small mb-0"><i>{getReportTime(report.updatedOn)}</i></p>
                    </div>
                </div>
            )
        }
        else if (name === 'Category') {
            return (
                <span>{report.categoryDisplayName}</span>
            )
        }
        else if (name === 'Shared with') {
            return (
                <div className="row">
                    {
                        report.sharedWith.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-chip w-auto mb-1"><i className="fa fa-user-o" aria-hidden="true"></i>{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
        else if (name === 'Description') {
            return (
                <span>{report.description}</span>
            )
        }
        else if (name === 'Report Type') {
            return (
                <span className={`badge badge-pill ${reportTypeBackground(report.reportType)}`}>{report.reportType}</span>
            )
        }
        else if (name === 'Created By') {
            return (
                <span>{report.createdBy}</span>
            )
        }
        else if (name === 'Action') {
            return (
                <div>
                    {
                        !report.hasAccess ? <button className="btn btn-primary btn-sm">Request Access</button> : null
                }
                </div>

            )
        }
        else if (name === 'User Groups') {
            return (
                <div className="d-flex">
                    {
                        report.userGroups.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-chip">{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const addColumnWidth = (column) => {
        if(column === 'Report Type'){
            return {width: '8rem'}
        }
        else if(column === 'Shared with'){
            return {width: '13rem'}
        }
        // else if(column === 'Action' && catalog) {
        //     return {width: '15rem'}
        // }
        else return ''
    }

    const openReport = (report) => {
        nav(`/analytics/reports/${report.data.reportCatSlug}/${report.data.reportNameSlug}`)
    }

    return (
        <DataTable value={nodes} onRowClick={openReport} className="cp">
            <Column field="companyName" header="Report Name" body={(e) => arrangeColumnValue(e, 'REPORT')} sortable={!catalog}></Column>
            {/* <Column style={{ width: '8rem' }} field="reportCategory" className="bl-width-8rem1" header="Category" sortable></Column> */}
            <Column style={{ width: '7rem' }} field="createdBy" header="Created By"sortable={!catalog}></Column>

            {
                reportColumn && reportColumn.map((column, i) => {
                    return (
                        <Column key={`table-column-${i}`} style={addColumnWidth(column)} field="sharedWith" header={column} body={(e) => arrangeColumnValue(e, column)} sortable={!catalog}></Column>
                    )
                })
            }

        </DataTable>
    )
}