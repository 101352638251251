import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import joyleafLogo from './../assets/logo/joyleaf-logo.webp';
import joyleafStoreImg from "./../assets/img/JoyleafStoreImagewebp.webp"
import bllogocolored from './../assets/logo/Logo_Colored.png';
import loginLabel from "./../utils/property-file/login.json";
import { getApplicationInfo, getToken } from "../services/authService";


const LoginComponent = () => {
    window.scrollTo(0, 0)

    const nav = useNavigate();
    const [ssoAuth, setSSOAuth] = useState(null)

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('bl-login-bg');
        sessionValidation()
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('bl-login-bg')
        }
    }, [])

    const sessionValidation = () => {
        if (getToken()) {
            navigateToNextPage()
        }
        else {
            getClientId()
        }
    }

    const getClientId = async () => {
        const res = await getApplicationInfo();
        if (res && res.success) {
            const clientId = res.data.clientId;
            const appName = res.data.applicationName ? res.data.applicationName : 'joyleaf.com'; // brownloop.com
            const redirect = `${window.location.origin}/`;
            let url = `https://login.microsoftonline.com/${appName}/oauth2/authorize?scope=openid&client_id=${clientId}&response_type=code&redirect_uri=${redirect}&response_mode=query`;
            setSSOAuth(url)
        }
    }

    const navigateToNextPage = () => {
        setTimeout(() => {
            nav('/products')
        }, 200)
    }

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top d-sm-flex justify-content-between">
                <img src={joyleafLogo} height="50px" className="navbar-brand" alt="brand-logo" />
                {/* <div className="text-primary"><i className="fa fa-globe"></i> {loginLabel.LANG_ENG}</div> */}
            </nav>


            <main role="main" className="container">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-5 col-lg-5 col-md-9 mt-4">
                            <div className="card o-hidden border-0 shadow-lg mt-5 pb-4">

                                <div className="card-body p-0">

                                    <div className="row">
                                        <div className="col-12 text-center p-5"><img className="card-img1" src={joyleafStoreImg} alt="Card image" height="350px" /></div>
                                        <div className="col-12">
                                            <div className="px-5">
                                                <div className="text-center">
                                                    <h1 className="h5 text-primary mb-4">Login to <b>Joyleaf Cloud Platform</b></h1>
                                                </div>
                                                <form className="user">
                                                    <a href={ssoAuth} className="btn btn-primary btn-block">
                                                        <i className="fa fa-windows fa-fw"></i> Single Sign-On (SSO)
                                                    </a>
                                                </form>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>

            <footer className="footer bl-login-footer p-0">
                <div className="container text-center">
                    <img src={bllogocolored} height="50px" className="navbar-brand" alt="brand-logo" />
                    <span className="text-muted">Powered by BL Cloud</span>
                </div>
            </footer>


            <div className="container d-none">
                <br /><br /><br /><br /><br />
                <div className="row justify-content-center mt-4">
                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg mt-5rem">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                        <div className="p-5">
                                            <h1 className="h2 text-white mb-4">{loginLabel.APP_TITLE} <br /><b>{loginLabel.PLATFORM}</b></h1>
                                            <div className="h4 text-white mb-5"><h4>{loginLabel.APP_DESC}</h4>​</div>
                                            <button className="btn btn-outline-light btn-user btn-block secondary-link">
                                                <i className="fab fa-microsoft fa-fw"></i> {loginLabel.ACCOUNT_BTN}
                                            </button>
                                            <br /><br /><br /><br />
                                            <div className="small text-white mb-1">{loginLabel.LEARN}</div>
                                            <div className="small text-white mb-4">{loginLabel.LEARN_SPECIFIC}</div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center py-3">
                                                <img src={joyleafLogo} alt="logo" className="img-fluid bl-h-2 mb-4" />
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user vista_login_formElements" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Email Id" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user vista_login_formElements" id="exampleInputPassword" placeholder="Password" />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">{loginLabel.REMEMBER}</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <button onClick={() => nav('/products')} className="btn btn-secondary btn-block mr-2">
                                                        {loginLabel.LOGIN}
                                                    </button>
                                                    <a href={ssoAuth} className="btn btn-primary btn-block mt-0">
                                                        {loginLabel.CONTINUE}
                                                    </a>
                                                </div>

                                            </form>
                                            <br />
                                            <div className="text-center">
                                                <button className="btn btn-link p-0"><small>{loginLabel.FORGOT_ID}</small></button>
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-link p-0"><small>{loginLabel.FORGOT_PWD}</small></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>

    )
}

export default LoginComponent;