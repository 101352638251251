import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logoIcon from "./../../assets/icons/joyleaflogo.png";
import LayoutContext from "../../services/context/layout/context";


export const MenuComponent = () => {

    const { breadcrumbContext } = useContext(LayoutContext);

    const openSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("blHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        if (isMobile) {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && !element3.classList.contains('mob-sub-menu')) {
                element3.classList.add('mob-sub-menu');
            }
        }
        else {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("collapsed-header-margin-sub-menu");
            }

            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("collapsed-content-margin-sub-menu");
            }
        }
        if (element3 && element3.classList.contains('close')) {
            element3.classList.remove("close")
        }
    }

    const closeSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("blHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        if (isMobile) {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && element3.classList.contains('mob-sub-menu')) {
                element3.classList.remove('mob-sub-menu');
            }
        }
        else {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("collapsed-header-margin-sub-menu");
            }

            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("collapsed-content-margin-sub-menu");
            }
        }
        if (element3 && !element3.classList.contains('close')) {
            element3.classList.add("close")
        }
    }

    const nav = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('dashboard')

    useEffect(() => {
        openSubMenu()
        mainPageContent();
    }, [location])

    useEffect(() => {
        if (breadcrumbContext && breadcrumbContext.length) {
            if (breadcrumbContext[0].hasSubMenu) {
                let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
                // if (isMobile) {
                //     closeSubMenu()
                // }
                // else {
                //     openSubMenu();
                // }
            }
            else {
                // closeSubMenu()
            }

        }
    }, [breadcrumbContext])


    useEffect(() => {

    }, [activeTab])


    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            setActiveTab(pathArray[1])
        }
    }

    return (
        <ul className="navbar-nav navbar-light sidebar accordion fixed-top toggled mainmenu" id="accordionSidebar" >

            <button className="sidebar-brand d-flex align-items-center justify-content-center cp mb-2">
                <div className="sidebar-brand-icon">
                    <img src={logoIcon} height="30px" alt="logo" />
                </div>
            </button>

            <hr className="sidebar-divider" />


            <li className={activeTab === 'products' ? 'nav-item active pt-2' : 'nav-item pt-2'}>
                <button className="nav-link" onClick={() => { nav('/products'); setActiveTab('products') }}>
                    <div className="active-menu">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                    </div>
                    <span>Products</span>
                </button>
            </li>
            <li className={activeTab === 'analytics' ? 'nav-item active pt-2' : 'nav-item pt-2'}>
                <button className="nav-link" onClick={() => { nav('/analytics/overview'); setActiveTab('analytics') }}>
                    <div className="active-menu">
                        <i className="fa fa-pie-chart" aria-hidden="true"></i>
                    </div>
                    <span>Analytics</span>
                </button>
            </li>
            {/* <li className={activeTab === 'dashboard' ? 'nav-item active pt-2' : 'nav-item pt-2'}>
                <button className="nav-link" onClick={() => { nav('/dashboard'); setActiveTab('dashboard') }}>
                    <div className="active-menu">
                        <i className="fa fa-align-center"></i>
                    </div>
                    <span>Activity Logs</span>
                </button>
            </li> */}
        </ul>
    )
}