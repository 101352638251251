import React, { useState } from 'react';
import LayoutContext from './context';


const LayoutContextProvider = ({ children }) => {
  const [breadcrumbContext, setBreadcrumbContext] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [cabinetSelection, setCabinetSelection] = useState(null);

  return (
    <LayoutContext.Provider value={{ breadcrumbContext, setBreadcrumbContext, globalSearch, setGlobalSearch, cabinetSelection, setCabinetSelection }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;