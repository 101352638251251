import chill from "./img/effect/chill.jpg";
import focus from "./img/effect/focus.jpg";
import rise from "./img/effect/rise.jpg";
import sleep from "./img/effect/sleep.jpg";

import hybrid from "./img/species/Hybrid.jpg";
import hybridindica from "./img/species/Hybrid_Indica.jpg";
import hybridsativa from "./img/species/Hybrid_Sativa.jpg";
import indica from "./img/species/Indica.jpg";
import sativa from "./img/species/Sativa.jpg";

export const effectIcons = {
    chill, focus, rise, sleep
}

export const speciesIcons = {
    hybrid, hybridindica, hybridsativa, indica, sativa
}
