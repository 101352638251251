import React, { useContext, useEffect, useState } from "react";
import { getCabinetsService } from "../../services/productService";
import LayoutContext from "../../services/context/layout/context";

export const FilterProductComponent = () => {

    const { setCabinetSelection } = useContext(LayoutContext);
    const [filters, setFilters] = useState([])
    const [isUnassignedProduct, setIsUnassignedProduct] = useState(false)

    useEffect(() => {
        setIsUnassignedProduct(false)
        getCabinets()
    }, [])

    useEffect(() => {
        // if (isUnassignedProduct) {
            filterProductByCabinet(filters)
        // }
    }, [isUnassignedProduct])

    const getCabinets = async () => {
        let resp = await getCabinetsService();
        if (resp.success) {
            let list = resp.data.map(f => {
                f.isSelected = false;
                return f;
            })
            setFilters(list)
        }
    }

    const filterCabinet = (indx) => {
        let list = filters;
        list[indx].isSelected = !list[indx].isSelected;
        setFilters(list)
        console.log("filterCabinet in filter -----");
        filterProductByCabinet(list, true)
    }

    const filterProductByCabinet = (list, removeUnassign) => {
        // console.log("filterProductByCabinet ===== in filter -------");
        let cabinets = list.filter(item => item.isSelected);
        if (removeUnassign) {
            setIsUnassignedProduct(false)
            setCabinetSelection({ cabinets: cabinets, unassignedProduct: isUnassignedProduct })
        }
        else if (isUnassignedProduct) {
            let filterObj = filters.map(f => {
                f.isSelected = false;
                return f;
            })
            setFilters(filterObj)
            setCabinetSelection({ cabinets: [], unassignedProduct: isUnassignedProduct })
        }
        else {
            setCabinetSelection({ cabinets: cabinets, unassignedProduct: isUnassignedProduct })
        }
    }

    const unassignedProduct = () => {
        setIsUnassignedProduct(!isUnassignedProduct)
    }

    return (
        <li className="nav-item">
            <a className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-target={`#cabinet-filter-nav`} data-bs-toggle="collapse">
                <span>{'By Cabinet'}</span><i className="fa fa-chevron-down ms-auto"></i>
            </a>
            <ul id="cabinet-filter-nav" className="nav-content collapse p-0 show">
                {
                    filters.map((menu, j) => {
                        return (
                            <li key={`risk-menu-${j}`}>
                                <button className={`p-1 nav-link`} >
                                    <div className="form-check">
                                        <input className="form-check-input border-white bl-bg-purple" type="checkbox" onChange={() => filterCabinet(j)} value="" id={`filter${j}`} checked={menu.isSelected} />
                                        <label className="form-check-label d-flex justify-content-between align-items-center" htmlFor={`filter${j}`}>
                                            {menu.cabinetName} <span className="bl-report-count bl-h-fit-content mr-1">{menu.productCount ? menu.productCount : '0'}</span>
                                        </label>
                                    </div>
                                </button>
                            </li>
                        )
                    })
                }
                {
                    filters.length ? (
                        <>
                            <hr className="sidebar-divider border-white mx-0" />
                            <li>
                                <button className={`p-1 nav-link`} >
                                    <div className="form-check">
                                        <input className="form-check-input border-white bl-bg-purple" type="checkbox" onChange={() => unassignedProduct()} value="" id={`filter-unassigned`} checked={isUnassignedProduct} />
                                        <label className="form-check-label" htmlFor={`filter-unassigned`}>
                                            {'Unassigned'}
                                        </label>
                                    </div>
                                </button>
                            </li>
                        </>
                    ) : null
                }
            </ul>

        </li>
    )
}