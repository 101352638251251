import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { PageLayout } from './Components/pageLayout';
import { DashboardComponent } from './Components/dashboard';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "react-datepicker/dist/react-datepicker.css";
import LoginComponent from './Components/login';
import { PageNotFoundComponent } from './Components/Error/pageNotFound';
import { ProductsComponent } from './Components/Products';
import { AnalyticsOverviewComponent } from './Components/Analytics/overview';
import { AnalyticsReportListComponent } from './Components/Analytics/reportList';
import { AnalyticsSelfServiceComponent } from './Components/Analytics/selfService';
import { AnalyticsCatalogComponent } from './Components/Analytics/catalog';
import { AnalyticsManageReportComponent } from './Components/Analytics/configure/manageReport';
import { AnalyticsManageReportCategoryComponent } from './Components/Analytics/configure/manageReportCategory';
import { AnalyticsViewReportComponent } from './Components/Analytics/reportView';
import AuthValidationComponent from './Components/authValidation';
import UnknownErrorComponent from './Components/Error/unknownError';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LayoutContextProvider from './services/context/layout/provider';

function App() {

  return (
    <div>
      <HashRouter>
      <LayoutContextProvider>
        <Routes>
          <Route element={<PageLayout />}>
            <Route path='/dashboard' element={<DashboardComponent />} />
            <Route path='/products' element={<ProductsComponent />} />
            <Route path='/analytics/overview' element={<AnalyticsOverviewComponent />} />
            <Route path='/analytics/reports/:id' element={<AnalyticsReportListComponent />} />
            <Route path='/analytics/self-service' element={<AnalyticsSelfServiceComponent />} />
            <Route path='/analytics/catalog' element={<AnalyticsCatalogComponent />} />
            <Route path='/analytics/manage-report' element={<AnalyticsManageReportComponent />} />
            <Route path='/analytics/manage-report-category' element={<AnalyticsManageReportCategoryComponent />} />
            <Route path='/analytics/reports/:catId/:reportId' element={<AnalyticsViewReportComponent />} />
            <Route path='/error' element={<UnknownErrorComponent />} />
          </Route>
          <Route path='/' element={<AuthValidationComponent />} />
          <Route path='/login' element={<LoginComponent />} />
          <Route path='/*' element={<PageNotFoundComponent />} />
        </Routes>
        </LayoutContextProvider>
      </HashRouter>
      <ToastContainer position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
    </div>
  );
}

export default App;
