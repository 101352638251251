import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import reportThumbnail1 from "./../../assets/img/report/thumbnail/report-1.png"
// import reportThumbnail121 from "./../../assets/img/report/thumbnail/Rep1_Thumb.png"
import reportThumbnail2 from "./../../assets/img/report/thumbnail/Rep2_Thumb.png"
import reportThumbnail3 from "./../../assets/img/report/thumbnail/Rep3_Thumb.png"
import reportThumbnail4 from "./../../assets/img/report/thumbnail/Rep4_Thumb.png"
import reportThumbnail5 from "./../../assets/img/report/thumbnail/Rep5_Thumb.png"
import reportThumbnail6 from "./../../assets/img/report/thumbnail/Rep6_Thumb.png"
import reportThumbnail7 from "./../../assets/img/report/thumbnail/Rep7_Thumb.png"
import moment from "moment";


export const GridViewComponent = ({ reports }) => {

    const [thumbnail] = useState([
        {
            id:1,
            icon:reportThumbnail1
        },
        {
            id:2,
            icon:reportThumbnail2
        },
        {
            id:3,
            icon:reportThumbnail3
        },
        {
            id:4,
            icon:reportThumbnail4
        },
        {
            id:5,
            icon:reportThumbnail5
        },{
            id:6,
            icon:reportThumbnail6
        },{
            id:7,
            icon:reportThumbnail7
        },
        {
            id:8,
            icon:reportThumbnail1
        },
        {
            id:9,
            icon:reportThumbnail2
        },
        {
            id:10,
            icon:reportThumbnail3
        },
        {
            id:11,
            icon:reportThumbnail4
        },
        {
            id:12,
            icon:reportThumbnail5
        },{
            id:13,
            icon:reportThumbnail6
        },{
            id:14,
            icon:reportThumbnail7
        }
    ]);
    const [reportList, setReportList] = useState(reports);
    const nav = useNavigate();

    useEffect(()=>{
        // let repo = reports.map((r)=>{
            
        //     return r;
        // });
        setReportList(reports)
        
    }, [reports])

    const openReport = (report) => {
        nav(`/analytics/reports/${report.reportCatSlug}/${report.reportNameSlug}`)
    }

    const getReportTime = (reportdate) => {
        if (reportdate) {
            let now = moment();
            let reportTime = moment(reportdate);            
            let monthDiff = now.diff(reportTime, 'months');
            if(monthDiff === 0){
                let dateDiff = now.diff(reportTime, 'days');
                if(dateDiff === 0){
                    let hoursDiff = now.diff(reportTime, 'hours');
                    if(hoursDiff === 0){
                        let mintDiff = now.diff(reportTime, 'minutes');
                        if(mintDiff === 0){
                            return 'Updated now'
                        }
                        else return `Updated ${mintDiff} mins ago`
                    }
                    else return `Updated ${hoursDiff} hours ago`
                }
                else if(dateDiff === 1) {
                    let dateCalendar = reportTime.calendar({
                        sameDay: '[Today at] hh:mm A',
                        nextDay: '[Tomorrow]',
                        nextWeek: 'dddd',
                        lastDay: '[Yesterday at] hh:mm A',
                        lastWeek: '[Last] dddd',
                        sameElse: 'MMM DD, YYYY'
                    })
                    return `Updated ${dateCalendar}`
                }
                else return `Updated ${dateDiff} days ago`
            }
            else return `Updated ${monthDiff} months ago`
        }
    }

    return (
        <div className="row">
            {
                reportList.map((report, i) => {
                    return (
                        <div key={`grid-report-${i + 1}`} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="card shadow-sm mb-4">
                                <img className="img-fluid card-img-top bl-grid-img" src={report.thumbnailURL} alt="Card cap" />
                                {/* <!-- Card Body --> */}
                                <div className="card-body">
                                    <h6 className="card-title">{report.displayName}</h6>
                                    <p className="card-text vista_text h-45px">{report.description}</p>
                                    <p className="card-text"><span className="badge badge-pill badge-primary">{report.reportType ? report.reportType : 'PowerBI'}</span>&nbsp;&nbsp;<small className="text-muted">{getReportTime(report.updatedOn)}</small></p>
                                    <div className="d-flex justify-content-between mb-0">
                                        <div>
                                            {!report.hasAccess ? (
                                                <a className="cp" rel="nofollow" onClick={()=>openReport(report)}>Open Report &rarr;</a>
                                            ) : (<a className="cp" rel="nofollow">Request Access &rarr;</a>)}
                                        </div>
                                        <div>
                                            {
                                                report.isPinned ? (<i className="fa fa-thumb-tack" aria-hidden="true"></i>) : (<i className="fa fa-thumb-tack text-gray" aria-hidden="true"></i>)
                                            }
                                        </div>
                                        {/* <div className="star-rating">
                                            <input type="checkbox" checked={report.pinned} id={`mark-fav${report.id}`} value="1" />
                                            <label htmlFor={`mark-fav${report.id}`} className="star mb-0" title="Mark Favorite">&#9733;</label>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}