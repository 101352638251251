import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authenticateService, storeToken, storeUserInfo } from "../services/authService";

const AuthValidationComponent = () => {

    const nav = useNavigate();
    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });
        const code = params && params.code ? params.code : null;
        console.log("get code params ===>", code, params);
        if (!code) {
            nav('/login')
            // window.location.href = "/#/login"
        }
        else authenticateSSOCode(code)
    }, [])

    const authenticateSSOCode = async (code) => {
        const res = await authenticateService(code);
        console.log("get resp on auth ===>", res);
        if (res && res.success) {
            let obj = {
                username: res.data.displayName,
                email: res.data.email
            }
            storeUserInfo(obj);
            storeToken(res.data.sessionToken);
            if(res.data.userGroups && res.data.userGroups.length === 0){
                localStorage.setItem('Unauthorized-Access', 'true')
                nav('/unauthorize-access')
            }
            else {
                localStorage.removeItem('Unauthorized-Access')
                window.location.href = "/#/products"
                // nav('/products')
            }
        }
    }

}

export default AuthValidationComponent;