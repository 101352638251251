import axios from "axios";
import { getToken, removeToken } from "./authService";
const apiKey = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: apiKey
})

instance.interceptors.request.use((config)=>{
    config.headers['Authorization'] = `${getToken()}`;
    return config;
}, (err)=>{
    return Promise.reject(err);
})

instance.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    if(!err.response){
        window.location.href='/#/system-down'
    }
    else if (err.response.status === 401) {
        removeToken()
        window.location.href = "/";
    }
    // else if(err.response.status === 500){
    //     window.location.href = "/#/error";
    // }
     
    return Promise.reject(err.response);
})

const products = "/products";
const cabinets = "/cabinets";

export async function getProductListService(obj, filters) {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return instance.post(`${products}${queryParams}`, filters)
    }
    catch(e){
        return e;
    }
}

export const getProductCountService = async(obj, filters) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return instance.post(`${products}/count${queryParams}`, filters)
    }
    catch(e){

    }
}

export async function getProductImageService(productId) {
    try {
        return instance.get(`${products}/img/${productId}`)
    }
    catch(e){
        return e;
    }
}

export async function getProductInformationService(productId) {
    try {
        return instance.post(`${products}/${productId}`)
    }
    catch(e){
        return e;
    }
}

export async function getCabinetsService() {
    try {
        return instance.get(cabinets)
    }
    catch(e){
        return e;
    }
}

export async function getCategoryByCabinetsService(cabinetIds) {
    try {
        return instance.post(`${cabinets}/category`, cabinetIds)
    }
    catch(e){
        return e;
    }
}

export async function getSubCategoryAndStrianByCabinetCategoryService(cabinetIds, category) {
    try {
        return instance.post(`${cabinets}/category/${category}`, cabinetIds)
    }
    catch(e){
        return e;
    }
}

export const modifyProductCabinetService = async (obj) => {
    try {
        return instance.post(`${cabinets}/product/modify`, obj)
    }
    catch(e){
        return e;
    }
}

export const storeProductCustomCBDTHCService = async (obj) => {
    try {
        return instance.post(`${products}/add/custom/data`, obj)
    }
    catch(e){
        return e;
    }
}

export const storeProductShortDescriptionService = async (obj) => {
    try {
        return instance.post(`${products}/add/description`, obj)
    }
    catch(e){
        return e;
    }
}