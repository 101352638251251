import React from "react";
import cnbc from "./../../assets/img/cnbc.png";
// import news2 from "./../../assets/img/news2.png";


export const RightPanelComponent = () => {

    const recentlyUsedAppGrid = () => {
        return (
            <div className="recent-app-grid">
                <div className="grid-app">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Risk Monitor</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-facebook-official" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">ESG</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">DealFlow</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-google" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Valuation</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Fundraising</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Sourcing</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Analytics</p>
                </div>
                <div className="grid-app">
                    <i className="fa fa-facebook-official" aria-hidden="true"></i>
                    <p className="small mb-0 text-nowrap">Task</p>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <section className="col-12 bg-dark h-13rem overflow-y-auto recent-app">
                <div className="text-white">
                    <div className="pt-3 text-white">
                        <div className="col-12">
                            <p className="font-weight-600">Recently Used</p>
                        </div>
                        {
                        recentlyUsedAppGrid()
                    }
                        
                    </div>
                </div>
            </section>
            <section className="bg-gray p-3">
                <div className="row p-2">
                    <div className="col-6">
                        <h5 className="h5 mb-0 text-black font-weight-600">My Feed</h5>
                    </div>
                    <div className="col-6 text-right">
                        <div className="btn-group">
                            <select id="contentview" className="form-control blc_button text-black" defaultValue={''}>
                                <option>All Topics</option>
                                <option>....</option>
                            </select>
                        </div>
                    </div>
                    
                </div>
                <div className="feed">
                    <div className="post-item clearfix">
                        <span><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                        <p>Q3 GDP Growth Exceeds Expectations at 6.5%, Inflation Rate Remains Stable</p>
                        <p className="small">59 minutes ago</p>
                    </div>
                    <div className="post-item clearfix">
                        <span><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                        <p>Healthcare Sector Surges as FDA Approves New Drug</p>
                        <p className="small">2 hours ago</p>
                    </div>
                    <div className="post-item clearfix">
                        <span><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                        <p>SEC Announces Amendments to Fair Value Measurement Standards.</p>
                        <p className="small">2 hours ago</p>
                    </div>
                    <div className="post-item clearfix">
                        <span><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                        <p>USD Strengthens Against Major Currencies, Affecting International Valuations.</p>
                        <p className="small">2 hours ago</p>
                    </div>
                    <div className="post-item clearfix">
                        <span><i className="fa fa-user-circle" aria-hidden="true"></i></span>
                        <p>Government Announces Tax Incentives for Renewable Energy Companies.</p>
                        <p className="small">2 hours ago</p>
                    </div>
                </div>
                <div>
                    <div className="card mb-4 blc_card news-feed">
                        <div className="card-body">
                            <p className="small">CNBC <span>| Yesterday</span></p>
                            <div className="post-item clearfix">
                                <img src={cnbc} alt="" />
                                <p><a href="www.google.com/news" target={'_blank'}>Stock Market Hits New All-Time Highs Amid Positive Earnings Reports.</a></p>
                                <p className="small text-gray">google.com/news</p>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-4 blc_card news-feed">
                        <div className="card-body">
                            <p className="small">CNBC <span>| Today</span></p>
                            <div className="post-item clearfix">
                                {/* <img src={news2} alt="" /> */}
                                <p><a href="www.google.com/news" target={'_blank'}>Tech Giant Acquires Start-up in Multi-Billion Dollar Deal.</a></p>
                                <p className="small text-gray">google.com/news</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}