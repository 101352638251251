import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import blLogo from "./../../assets/icons/bl-white-logo.png";
import { FilterProductComponent } from "../Products/filter";
import { getPinnedReportListService, getReportCategoryListService } from "../../services/analyticsService";


export const SubMenuComponent = () => {

    const location = useLocation();
    const nav = useNavigate();
    const [activeSubTab, setActiveSubTab] = useState('');
    const [activeSupSubMenu, setActiveSupSubMenu] = useState('');
    const [pinnedReport, setPinnedReport] = useState([]);
    const [analyticsSubMenuList, SetAnalyticsSubMenuList] = useState([]);

    useEffect(() => {
        getAnalyticsReportCategory()
    }, [])

    useEffect(() => {
        setActiveSubTab(getSubMenu())
    }, [location])

    const getAnalyticsReportCategory = async () => {
        const resp = await getReportCategoryListService();
        if (resp.success) {
            getAnalyticsPinnedReportCategory();
            SetAnalyticsSubMenuList(resp.data)
        }
    }

    const getAnalyticsPinnedReportCategory = async () => {
        const resp = await getPinnedReportListService();
        if (resp.success) {
            setPinnedReport(resp.data)
        }
    }

    const getSubMenu = () => {
        setActiveSupSubMenu('')
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray.length === 5) {
                setActiveSupSubMenu(`${pathArray[3]}-${pathArray[4]}`)
                return pathArray[3]
            }
            return pathArray[2]
        }
    }

    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1)
            return pathArray[1];
    }

    const getSubMenuContent = () => {
        let mainMenu = mainPageContent();
        if (mainMenu === 'products') {
            return productsSubMenu();
        }
        else if (mainMenu === 'analytics') {
            return analyticsSubMenu();
        }
        else {
            return commonSubMenu();
        }
    }

    const getNavigationLink = (item, sectionType) => {
        if (sectionType === 'CATEGORIES') {
            return `/analytics/reports/${item.slug}`
        }
        else {
            return `/analytics/${item.slug}`
        }
    }

    const getAnalyticsSubMenu = (list, sectionType) => {
        return (
            list.map((menu) => {
                return (
                    <li key={`analytics-sub-${menu.name}-${sectionType}`} className={activeSubTab === menu.slug ? 'nav-item selected' : 'nav-item'}>
                        <button className={`nav-link d-flex justify-content-between align-items-center ${menu.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(menu, sectionType)); setActiveSubTab(menu.slug) }}>
                            <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                            {
                                sectionType === 'CATEGORIES' && <span className="bl-report-count">{menu.count ? menu.count : '0'}</span>
                            }
                        </button>
                    </li>
                )
            })
        )
    }

    const analyticsSubMenu = () => {

        return (
            <>
                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/analytics/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>
                {
                    pinnedReport.length ? (<div className="sidebar-heading pb-2 mt-4">
                        PINNED REPORTS
                    </div>) : null
                }
                {
                    pinnedReport.length ? pinnedReport.map((p, i) => {
                        return (
                            <li key={`pinned-report-${i + 1}`} className={activeSupSubMenu === `${p.reportCatSlug}-${p.reportNameSlug}` ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/analytics/reports/${p.reportCatSlug}/${p.reportNameSlug}`); setActiveSupSubMenu(`${p.reportCatSlug}-${p.reportNameSlug}`) }}>
                                    <span><i className="fa fa-fw fa-thumb-tack"></i> {p.displayName}</span></button>
                            </li>
                        )
                    }) : null
                }
                {
                    analyticsSubMenuList.length ? analyticsSubMenuList.map((items, i) => {
                        return (
                            <React.Fragment key={`analytics-sub-menu-${items.menuType}-${i + 1}`}>
                                {
                                    items.list.length ? (
                                        <div key={`analytics-sub-header-${i + 1}`} className="sidebar-heading pb-2 mt-4">
                                            {items.menuType}
                                        </div>
                                    ) : null
                                }
                                {
                                    getAnalyticsSubMenu(items.list, items.menuType)
                                }
                            </React.Fragment>
                        )
                    }) : null
                }
            </>
        )
    }

    const productsSubMenu = () => {
        return (
            <>
                <div className="sidebar-heading pb-2 mt-4 text-white">
                    FILTER
                </div>
                <FilterProductComponent></FilterProductComponent>
            </>
        )

    }

    const commonSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu" >
                <div className="sidebar-heading py-2">
                    General Settings
                </div>
            </ul>
        )
    }


    return (
        <div className="sub-menu-content">
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu" >
                <div className="mb-4">
                {getSubMenuContent()}
                </div>
                
                <footer className="position-fixed bottom-0 bl-menu-footer text-white">
                    <div className="d-flex justify-content-between p-2 border-top">
                        <p className="mb-0 align-self-center"><small><i>Powered By Brownloop Cloud</i></small></p>
                        <img src={blLogo} height="20px" alt="BL Logo" />
                    </div>

                </footer>
            </ul>
        </div>
    )
}