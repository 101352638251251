import axios from "axios";
const apiKey = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: apiKey
})

instance.interceptors.request.use((config)=>{
    if(getToken()){
        config.headers['Authorization'] = getToken();
    }
    return config;
}, (err)=>{
    return Promise.reject(err);
})

instance.interceptors.response.use((res)=>{
    return res.data;
}, (err)=>{
    if(!err.response){
        window.location.href='/#/system-down'
    }
    else if(err.response.status === 500){
        window.location.href = "/#/error";
    }
    return Promise.reject(err);
})

const appConfigURL = "/app/config";
const authenticationUrl = "/auth/authenticate";
const logoutURL = "/auth/logout";

export async function getApplicationInfo() {
    try {
        return instance.get(appConfigURL);
    }
    catch(e){
        return e;
    }
}

export async function getClientIdService(){
    try{
        return await instance.get(appConfigURL)
    }
    catch(e){
        return e;
    }
}

export async function authenticateService(code){
    try{
        return await instance.get(`${authenticationUrl}?code=${code}`)
    }
    catch(e){
        return e;
    }
}

export async function logoutService(code){
    try{
        return await instance.get(logoutURL)
    }
    catch(e){
        return e;
    }
}

export async function storeToken(token){
    localStorage.setItem("token", token)
}

export function getToken(){
    return localStorage.getItem("token")
}

export function removeToken(){
    localStorage.clear()
}

export function storeUserInfo(obj){
    localStorage.setItem("userInfo", JSON.stringify(obj))
}

export function getUserInfo(){
    return JSON.parse(localStorage.getItem("userInfo"))
}