import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { boolean, number, text } from "@storybook/addon-knobs";
import { useReactToPrint } from 'react-to-print';
import { PrintProductLabel } from "./printProductLabel";
import { getProductInformationService, storeProductCustomCBDTHCService, storeProductShortDescriptionService } from "../../services/productService";
import imgPreview from "./../../assets/img/no-img.png";
import cardPreview from "./../../assets/img/card-preview.png";
import moment from "moment";
import { toast } from "react-toastify";


export const ProductDetailModal = ({ productId, cabinet, reloadProduct }) => {

    const tooglesGroupId = 'Toggles';
    const valuesGroupId = 'Values';
    const mainGroupId = 'Main';

    const [product, setProduct] = useState({
        "productID": null,
        "dealerID": null,
        "productGroupID": null,
        "productGroupName": "",
        "thc": 0,
        "cbd": 0,
        "productName": "",
        "productClass": "",
        "productCategory": "",
        "productSubCategory": "",
        "productBrand": "",
        "productType": "",
        "productStrain": "",
        "productQualityLine": "",
        "finishedProduct": "",
        "externalBarCode": "",
        "uom": "",
        "packOfSize": null,
        "productPrice": null,
        "productStrainPrevalence": "",
        "description": "",
        "productEffectID": null,
        "productEffectName": "",
        "distributorID": null,
        "distributorName": "",
        shortDescription: "",
        updatedOn: "",
        inventoryCount: "",
        customCbd: "",
        customCbdSelected: false,
        customThc: "",
        customThcSelected: false
    })
    const [images, setImages] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [labelCount, setLabelCount] = useState(1)
    const [printedProducts, setPrintedProducts] = useState([])


    useEffect(() => {
        if(productId){
            setModalShow(true)
            setLabelCount(1)
            getProductInfo(productId)
        }
    }, [productId])

    const getProductInfo = async (productId) => {
        let resp = await getProductInformationService(productId);
        if (resp.success) {
            resp.data.cabinetName = cabinet;
            resp.data.customCbd = resp.data?.customCbd || "";
            resp.data.customCbdSelected = resp.data?.customCbdSelected || false;
            resp.data.customThc = resp.data?.customThc || "";
            resp.data.customThcSelected = resp.data?.customThcSelected || false;
            resp.data.shortDescription = resp.data?.shortDescription || "";
            setProduct(resp.data)
            let prodImg = [];
            if (resp.data.images) {
                prodImg = resp.data.images.map(i => {
                    return i.blobUrl ? i.blobUrl : i
                })
            }
            setImages(prodImg.length ? prodImg : [imgPreview])
        }
    }

    const getConfigurableModalProps = () => ({
        showArrows: boolean('showArrows', true, tooglesGroupId),
        showStatus: boolean('showStatus', false, tooglesGroupId),
        showIndicators: boolean('showIndicators', true, tooglesGroupId),
        infiniteLoop: boolean('infiniteLoop', true, tooglesGroupId),
        // showThumbs: boolean('showThumbs', true, tooglesGroupId),
        useKeyboardArrows: boolean('useKeyboardArrows', true, tooglesGroupId),
        autoPlay: boolean('autoPlay', false, tooglesGroupId),
        stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
        swipeable: boolean('swipeable', true, tooglesGroupId),
        // dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
        emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
        autoFocus: boolean('autoFocus', false, tooglesGroupId),
        thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
        selectedItem: number('selectedItem', 0, {}, valuesGroupId),
        interval: number('interval', 2000, {}, valuesGroupId),
        transitionTime: number('transitionTime', 500, {}, valuesGroupId),
        swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
        ariaLabel: text('ariaLabel', undefined),
    });

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printLabel = () => {
        let items = [];
        for (let i = 1; i <= labelCount; i++) {
            items.push(product)
        }
        setPrintedProducts(items)
        setTimeout(() => {
            handlePrint()
        }, 50)
    }

    const getCBDSelectionType = (val) => {
        let productInfo = { ...product };
        productInfo.customCbdSelected = val;
        setProduct(productInfo)
    }

    const getTHCSelectionType = (val) => {
        let productInfo = { ...product };
        productInfo.customThcSelected = val;
        setProduct(productInfo)
    }

    const setCustomTHCValue = (val) => {
        let productInfo = { ...product };
        productInfo.customThc = val;
        setProduct(productInfo)
    }

    const setCustomCBDValue = (val) => {
        let productInfo = { ...product };
        productInfo.customCbd = val;
        setProduct(productInfo)
    }

    const setProdShortDescription = (desc) => {
        let productInfo = { ...product };
        productInfo.shortDescription = desc;
        setProduct(productInfo)
    }

    const saveProductInfo = async (e) => {
        e.preventDefault();
        const obj = {
            productId: product.productID,
            customCbd: product.customCbd ? product.customCbd : null,
            customThc: product.customThc ? product.customThc : null,
            customCbdSelected: product.customCbdSelected ? 1 : 0,
            customThcSelected: product.customThcSelected ? 1 : 0
        }
        const customeUpdates = await storeProductCustomCBDTHCService(obj);
        if (customeUpdates && customeUpdates.success) {
            const descObj = {
                productId: product.productID,
                description: product.shortDescription
            }
            if (descObj.description) {
                const shortDescriptionUpdates = await storeProductShortDescriptionService(descObj);
                if (shortDescriptionUpdates && shortDescriptionUpdates.success) {
                    toast.success("Product updated successfully!")
                }
                else {
                    toast.success(shortDescriptionUpdates?.message || "Description not updated, please try again!")
                }
            }
            reloadProduct()
        }
        else {
            toast.success(customeUpdates?.message || "Description not updated, please try again!")
        }
    }

    return (
        <div className="modal fade" id="productdetailmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered bl-mw-75" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h6 className="modal-title" id="exampleModalLongTitle">Product Details</h6>
                            <p className="textSize3 text-muted mb-0">Last Update: {product.updatedOn ? moment(product.updatedOn).format('lll') : ''}</p>
                        </div>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModalShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-2 text-center">
                                        <Carousel
                                            {...getConfigurableModalProps()} dynamicHeight={true} showThumbs={false}>
                                            {
                                                images && images.map((img, i) => {
                                                    return (
                                                        <div key={`carousel-img-${i}`}>
                                                            <img src={img} alt="carousel-image" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                    <div className="col-6">
                                        <table width="100%" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="textSize2 text-muted">SKU#{product.productID}</div>
                                                        <div className="textSize1 mb-1"><b>{product.productGroupName}</b></div>
                                                        <button type="button" className="btn btn-sm btn-outline-secondary textSize3 mb-2 px-1 py-0">{product.productBrand}</button>
                                                    </td>
                                                    {/* <td width="15%" align="center"><img src="https://static.thenounproject.com/png/3829037-200.png" height="20px" /><p className="textSize3 text-warning">Running Low</p></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row mb-2">
                                            <div className="col textSize2 border-right-light">
                                                <span className="text-gray-500">Category</span>
                                                <p className="mb-0">{product.productCategory}</p>
                                            </div>
                                            <div className="col textSize2 border-right-light">
                                                <span className="text-gray-500">Sub Category</span>
                                                <p className="mb-0">{product.productSubCategory}</p>
                                            </div>
                                            <div className="col textSize2 border-right-light">
                                                <span className="text-gray-500">Strain</span>
                                                <p className="mb-0">{product.productStrain}</p>
                                            </div>
                                            <div className="col textSize2 border-right-light">
                                                <span className="text-gray-500">Inventory</span>
                                                <p className={`mb-0 ${product.inventoryCount ? 'text-success' : 'text-danger'}`}>{product.inventoryCount ? product.inventoryCount : 'Out of Stock'}</p>
                                            </div>
                                            <div className="col textSize2">
                                                <span className="text-gray-500">Cabinet</span><br />
                                                {
                                                    cabinet ? <p className="mb-0">{cabinet}</p> : <p className="mb-0 badge bl-chip bg-danger text-white border-0">{'Not Assigned'}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="card border-0 rounded-0">
                                            <h6 className="card-header px-0 py-2 textSize2 bg-transparent rounded-0" data-bs-toggle="collapse" data-bs-target={`#collapseCardExample`} role="button" aria-expanded="true" aria-controls="collapseCardExample">
                                                Additional Information:
                                                <span className="bl-accordion-icon border-0">
                                                    <i className="fa fa-angle-down accordion-button p-0 bg-transparent" ></i>
                                                </span>
                                            </h6>
                                            <div className="collapse show" id="collapseCardExample">
                                                <div className="card-body py-2 px-0 textSize2">
                                                    {product.description}
                                                </div>
                                                <ul className="list-group textSize3">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                        <p className="mb-0 text-muted">Variant</p>
                                                        <p className="mb-0">{product.productName}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                        <p className="mb-0 text-muted">Product Type</p>
                                                        <p className="mb-0">{product.productType}</p>
                                                    </li>
                                                    <li className="list-group-item p-2 productCardOddRow border-0">
                                                        <div className="row">
                                                            <div className="col-4 align-content-center">
                                                                <p className="mb-0 text-muted">CBD</p>
                                                            </div>
                                                            <div className="col-8">
                                                                <form>
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <div className="input-group">
                                                                                <input className="form-check-input position-relative mr-2 align-self-center" value={false} checked={!product.customCbdSelected} onChange={() => getCBDSelectionType(false)} type="checkbox" name="gridRadios" />
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text form-control-sm bl-font-14px">Sweed</div>
                                                                                </div>
                                                                                <input type="text" className="form-control form-control-sm bg-light" defaultValue={product.cbd ? `${product.cbd}%` : '0%'} id="disabledcbd" placeholder="CBD Value" readOnly />
                                                                            </div>
                                                                        </div>
                                                                        <div className="bl-break-line"></div>
                                                                        <div className="d-flex">
                                                                            <div className="input-group">
                                                                                <input className="form-check-input position-relative mr-2 align-self-center" value={true} checked={product.customCbdSelected} onChange={() => getCBDSelectionType(true)} type="checkbox" name="gridRadios" />
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text form-control-sm bl-font-14px">Custom</div>
                                                                                </div>
                                                                                <input type="text" className="form-control form-control-sm bg-white" value={product.customCbd} onChange={e => setCustomCBDValue(e.target.value)} id="customcbd" placeholder="CBD Value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item p-2 productCardEvenRow border-0">
                                                        <div className="row">
                                                            <div className="col-4 align-content-center">
                                                                <p className="mb-0 text-muted">THC</p>
                                                            </div>
                                                            <div className="col-8">
                                                                <form>
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <div className="input-group">
                                                                                <input className="form-check-input position-relative mr-2 align-self-center" value={false} checked={!product.customThcSelected} onChange={() => getTHCSelectionType(false)} type="checkbox" name="gridRadios" />
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text form-control-sm bl-font-14px">Sweed</div>
                                                                                </div>
                                                                                <input type="text" className="form-control form-control-sm bg-light" defaultValue={product.thc ? `${product.thc}%` : '0%'} id="disabledthc" placeholder="THC Value" readOnly />
                                                                            </div>
                                                                        </div>
                                                                        <div className="bl-break-line"></div>
                                                                        <div className="d-flex">
                                                                            <div className="input-group">
                                                                                <input className="form-check-input position-relative mr-2 align-self-center" value={true} checked={product.customThcSelected} onChange={() => getTHCSelectionType(true)} type="checkbox" name="gridRadios" />
                                                                                <div className="input-group-prepend">
                                                                                    <div className="input-group-text form-control-sm bl-font-14px">Custom</div>
                                                                                </div>
                                                                                <input type="text" className="form-control form-control-sm bg-white" value={product.customThc} onChange={(e) => setCustomTHCValue(e.target.value)} id="customthc" placeholder="THC Value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                        <p className="mb-0 text-muted">Strain Prevalence</p>
                                                        <p className="mb-0">{product.productStrainPrevalence}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                        <p className="mb-0 text-muted">Quality Line</p>
                                                        <p className="mb-0">{product.productQualityLine}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                        <p className="mb-0 text-muted">Price</p>
                                                        <p className="mb-0">{product.productPrice}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                        <p className="mb-0 text-muted">UoM</p>
                                                        <p className="mb-0">{product.uom}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                        <p className="mb-0 text-muted">Pack Size</p>
                                                        <p className="mb-0">{product.packOfSize}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardEvenRow border-0">
                                                        <p className="mb-0 text-muted">Effect Details</p>
                                                        <p className="mb-0">{product.productEffectName}</p>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-2 productCardOddRow border-0">
                                                        <p className="mb-0 text-muted">Distributor Details</p>
                                                        <p className="mb-0">{product.distributorName}</p>
                                                    </li>
                                                    <li className="list-group-item p-2 productCardEvenRow border-0">
                                                        <div className="row">
                                                            <div className="col-4 align-content-center">
                                                                <p className="mb-0 text-muted">Card Description (100 char max)</p>
                                                            </div>
                                                            <div className="col-8">
                                                                <textarea className="form-control form-control-sm bg-white" maxLength={100} value={product.shortDescription} onChange={e => setProdShortDescription(e.target.value)} placeholder="Short Description"></textarea>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card border-0 rounded-0 d-none">
                                            <h6 className="card-header px-0 py-2 textSize2 bg-transparent rounded-0" data-bs-toggle="collapse" data-bs-target={`#collapseCardExample2`} role="button" aria-expanded="true" aria-controls="collapseCardExample2">
                                                Activity Log:
                                                <span className="bl-accordion-icon border-0">
                                                    <i className="fa fa-angle-down accordion-button p-0 bg-transparent" ></i>
                                                </span>
                                            </h6>
                                            <div className="collapse show" id="collapseCardExample2">
                                                <div className="card-body py-2 px-0 textSize2">
                                                    Recent Activity Content here
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        product.productCategory !== 'Accessories' ? (<div className="col-4">
                                            <div className="card bl-border-purple">
                                                <div className="card-header bl-border-bottom-purple bl-text-purple textSize2 px-3 py-2">
                                                    Product Card Preview
                                                </div>
                                                <div className="card-body bl-card-preview py-0">
                                                    <PrintProductLabel ref={componentRef} productInfo={[product]} cardCount={1} />
                                                </div>
                                            </div>
                                            <div className="col-6 offset-3 my-3">
                                                <div className="input-group input-group-sm mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => { labelCount > 1 ? setLabelCount(labelCount - 1) : setLabelCount(1) }}>
                                                            <i className="fa fa-minus" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control text-center h-auto bg-white bl-text-purple bl-border-color-purple" value={labelCount} disabled />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bg-white bl-text-purple bl-border-color-purple cp" onClick={() => setLabelCount(labelCount + 1)}>
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) : (
                                            <div className="col-4">
                                                <img src={cardPreview} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between p-1">
                        <button type="button" className="btn btn-outline-primary btn-sm" data-bs-dismiss="modal" onClick={() => setModalShow(false)}>Cancel</button>
                        <div>
                            <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={(e) => saveProductInfo(e)}>Update Value</button>
                            <button type="button" className="btn btn-primary btn-sm" disabled={product.productCategory === 'Accessories'} onClick={printLabel}>Print Label</button>
                        </div>
                    </div>
                    <div className="d-none">
                        <PrintProductLabel ref={componentRef} productInfo={printedProducts} cardCount={labelCount} />
                    </div>
                </div>
            </div>
        </div>
    )
}