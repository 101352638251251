import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { getReportEmbedTokenService, getReportInfoService } from "../../services/analyticsService";
import UnknownErrorComponent from "../Error/unknownError";
import reportLabel from "./../../utils/property-file/reportLabel.json";
import emptyIcon from "./../../assets/img/empty-report-icon.svg";
import unauthorizeIcon from "./../../assets/img/unauthorize.png";
import LayoutContext from "../../services/context/layout/context";
import { capitalizeFirstLetter } from "../../utils/common";



export const AnalyticsViewReportComponent = () => {
    window.scrollTo(0, 0);

    const { setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch } = useContext(LayoutContext);

    const location = useLocation();
    const [reports, setReports] = useState({
        reportName: '', appId: '', ctId: '', groupId: '', reportId: ''
    });
    const [isErrorOccured, setIsErrorOccured] = useState(false)
    const [embedUrl, setEmbedUrl] = useState('')
    const [embedToken, setEmbedToken] = useState('')
    const [reportPage, setReportPage] = useState([]);
    const [reportNotFound, setReportNotFound] = useState(false);
    const [authorizedReport, setAuthorizeReport] = useState(true);
    const [reportCrumb, setReportCrumb] = useState({
        reportCategorySlug: '', reportNameSlug: ''
    })

    useEffect(() => {
        const reportCatSlug = reportCrumb.reportCategorySlug && reportCrumb.reportCategorySlug.trim();
        const reportNameSlug = reportCrumb.reportNameSlug && reportCrumb.reportNameSlug.trim();
        setBreadcrumbContext(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: `/analytics/reports/${reportCatSlug}`, menuName: capitalizeFirstLetter(reportCatSlug), isActive: false, hasSubMenu: true },
                { slug: `/analytics/reports/${reportCatSlug}/${reportNameSlug}`, menuName: capitalizeFirstLetter(reportNameSlug), isActive: false, hasSubMenu: true }
              ]
        )
    }, [reportCrumb])

    useEffect(() => {

    }, [globalSearch])

    useEffect(() => {
        setReportNotFound(false);
        setAuthorizeReport(true)
        setIsErrorOccured(false)
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 5) {
                getReportInfo(pathArray[4])
            }
        }
    }, [location])

    useEffect(() => {
        if (reports.reportId) {
            getReportEmbedToken()
        }
    }, [reports])

    const getReportInfo = async (reportId) => {
        const resp = await getReportInfoService(reportId);
        if (resp && resp.success) {
            if (resp.data.reportId) {
                // setReportCrumb(`${resp.data.reportCatSlug}, ${resp.data.reportNameSlug}`)
                setReportCrumb({reportCategorySlug: resp.data.reportCatSlug, reportNameSlug: resp.data.reportNameSlug})
                setReports(resp.data)
                setReportNotFound(false);
                setAuthorizeReport(true);
            }
            else {
                setReportNotFound(true);
            }
        }
        else {
            setAuthorizeReport(false)
        }
    }

    const getReportEmbedToken = async () => {
        try {
            const resp = await getReportEmbedTokenService(reports.groupId, reports.reportId);
            if (resp && resp.statusCode && resp.statusCode === 403) {
                setAuthorizeReport(false)
            }
            else if (resp && resp.token) {
                setAuthorizeReport(true)
                setEmbedToken(resp.token)
                let url = `https://app.powerbi.com/reportEmbed?reportId=${reports.reportId}&groupId=${reports.groupId}`;
                setEmbedUrl(url)
            }
        }
        catch (e) {
            if (e && e.status === 500) {
                setIsErrorOccured(true)
            }
        }
    }

    const getReportPages = async () => {
        try {
            const pages = await window.Report.getPages();
            // pages = pages.filters(p => p.visibility === 0);
            setReportPage(pages)
        }
        catch (e) {
        }
    }

    const setReportActivatePage = async (i) => {
        try {
            const pages = await window.Report.getPages();
            await pages[i].setActive();
            getReportPages()
        }
        catch (errors) { }

    }

    const powerBiEmbedConfig = () => {
        return (<PowerBIEmbed
            embedConfig={{
                type: 'report',
                id: reports.reportId,
                embedUrl: embedUrl,
                accessToken: embedToken,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        },
                        pageNavigation: false
                    }
                }
            }}

            eventHandlers={
                new Map([
                    ['loaded', function (loaded) {
                        // getReportPages();
                    }],
                    ['rendered', async function (report) {
                        getReportPages();
                     }],
                    ['error', function (event) { }]
                ])
            }

            cssClassName={"bl-power-bi-report"}

            getEmbeddedComponent={(embeddedReport) => {
                window.Report = embeddedReport;
            }}
        />)
    }

    const getEmptyScreen = () => {
        return (
            <>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mt-5">
                    <div className="sidebar-brand-icon mb-2">
                        <img src={emptyIcon} height="80px" alt="empty-icon" />
                    </div>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <h1 className="h3 text-gray-800">{reportLabel.NOT_FOUND.REPO_TITLE}</h1>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <p>{reportLabel.NOT_FOUND.REPO_DESC}</p>
                </div>
            </>
        )
    }

    const unauthorizeReportScreen = () => {
        return (
            <>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mt-5">
                    <div className="sidebar-brand-icon mb-2">
                        <img src={unauthorizeIcon} height="80px" alt="empty-icon" />
                    </div>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <h1 className="h3 text-gray-800">{reportLabel.UNAUTHORIZE.TITLE}</h1>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <p>{reportLabel.UNAUTHORIZE.DESC}</p>
                </div>
            </>
        )
    }

    return (
        isErrorOccured ? <UnknownErrorComponent /> : (
            <div className="content">
                <div className="container-fluid pt-3">
                    <section className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h6>{reports.reportName}</h6>
                        </div>
                        <div className="d-inline-flex flex-row">
                            <a className="btn btn-secondary btn-sm mr-2 d-none"><i className="fa fa-download fa-sm"></i></a>
                            <a onClick={() => window.Report.print()} className="btn btn-secondary btn-sm mr-2"><i className="fa fa-print fa-sm"></i></a>
                            <a onClick={() => window.Report.fullscreen()} className="btn btn-secondary btn-sm"><i className="fa fa-expand fa-sm text-white-100"></i></a>
                        </div>
                    </section>
                    {
                        !reportNotFound && authorizedReport && reportPage.length > 1 ? (
                            <section className="mb-3">
                                <div className="text-center border-bottom pb-1">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        {
                                            reportPage.map((p, i) => {
                                                if (p.visibility === 0) {
                                                    return (
                                                        <button type="button" onClick={() => setReportActivatePage(i)} className={p.isActive ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'} key={`page-${i + 1}`}>{p.displayName}</button>
                                                    )
                                                }
                                            })
                                        }
                                    </div>


                                </div>
                            </section>
                        ) : null
                    }
                    <section className="mb-3">
                        {
                            !reportNotFound ? authorizedReport ? (
                                <div>
                                    <div id="powerbi-report-section" className="embed-responsive embed-responsive-16by9 h-82vh">
                                        {powerBiEmbedConfig()}
                                    </div>
                                </div>
                            ) : (unauthorizeReportScreen()) : (
                                getEmptyScreen()
                            )
                        }
                    </section>
                </div>
            </div>
        )
    )
}