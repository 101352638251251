import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { HeaderComponent } from "./Common/header";
import { MenuComponent } from "./Common/menu";
import { SubMenuComponent } from "./Common/subMenu";
import { getToken } from "../services/authService";



export const PageLayout = () => {

    const [showLayout, setShowLayout] = useState(false)
    useEffect(() => {
        document.getElementsByTagName("body")[0].classList.remove('bl-login-bg');
        sessionValidation()
    }, [])

    const sessionValidation = () => {
        if (!getToken()) {
            window.location.href = "/#/login"
        }
        else setShowLayout(true)
    }

    return (
        showLayout ? (
            <>
            <HeaderComponent />
            <div id="wrapper">
                <MenuComponent />
                <SubMenuComponent />
                <div id="content-wrapper" className="d-flex flex-column vistaContentMargin vistaCollapsedContentMargin collapsed-content-margin-sub-menu">
                    <Outlet />
                </div>
            </div>
        </>
        ) : null
    )
}