import React, { useContext, useEffect, useState } from "react";
import HeatmapChart from "./Graph/heatmap";
import heatmapJson from './../data/heatmap.json';
import { RightPanelComponent } from "./Common/rightPanel";
import { ColumnChart } from "./Graph/columnChart";
import { TrendChartComponent } from "./Graph/trendChart";
import LayoutContext from "../services/context/layout/context";

export const DashboardComponent = () => {

    const { breadcrumbContext, setBreadcrumbContext } = useContext(LayoutContext);
    const { globalSearch, setGlobalSearch } = useContext(LayoutContext);

    window.scrollTo(0, 0)

    const [panelVisibility, setPanelVisibility] = useState(true);

    const heatYAxisData = [
        { weekday: "ALTADIA" },
        { weekday: "GLOVIS" },
        { weekday: "FLENDER" },
        { weekday: "JIO" },
        { weekday: "SEPSA" },
        { weekday: "GCI" }
    ];

    const [incomePeriodData] = useState([
        {
            "date":"Jun 2022",
            "revenue": 120,
            "netIncome": 110
        },
        {
            "date":"Sep 2022",
            "revenue": 130,
            "netIncome": 80
        },
        {
            "date":"Dec 2022",
            "revenue": 150,
            "netIncome": 100
        }
    ])
    const [incomeSeries] = useState([
        {
            name: "Revenue", val: 'revenue'
        },
        {
            name: 'Net Income', val: 'netIncome'
        }
    ])

    useEffect(() => {
        setBreadcrumbContext([{ slug: '/dashboard', menuName: 'Dashboard', isActive: true, hasSubMenu: false }])
    }, [])

    useEffect(() => {

    }, [globalSearch])


    const dashHeat = (item) => {
        // nav(`/dashboard/company`, { state: item.weekday })
    }

    const changePageContentSize = () => {
        setPanelVisibility(!panelVisibility);
    }

    const cardGridContainer = () => {
        return (
            <div className="grid-container">
                <div className="grid-item item1">
                    <div className="card blc_card">
                        <div className="filter">
                            <button className="icon text-secondary btn-navigate" id="chartdropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                            <div className="dropdown-menu dropdown-menu shadow animated--grow-in" aria-labelledby="chartdropdown">
                                <button className="dropdown-item" >
                                    <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                                    View
                                </button>
                                <button className="dropdown-item" >
                                    <i className="fa fa-edit fa-sm mr-2 text-gray-400"></i>
                                    Update
                                </button>
                                <button className="dropdown-item" >
                                    <i className="fa fa-list fa-sm mr-2 text-gray-400"></i>
                                    Activity Log
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-2 font-weight-600">
                                <p>Data Collection Overview</p>
                            </div>
                            <HeatmapChart data={heatmapJson} yaxisData={heatYAxisData} yAxisVisiblity={true} height={'350px'} dashHeat={(item) => dashHeat(item)}></HeatmapChart>
                        </div>
                    </div>
                </div>
                <div className="grid-item item2">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                                <TrendChartComponent id="sourceprofilevaluationdash" height="9rem"></TrendChartComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item3">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Valuation Performance</p>
                                {
                                            incomePeriodData.length ? <ColumnChart id="incomestatementfinancedash" data={incomePeriodData} categoryField={'date'} seriesItem={incomeSeries} height="9rem"></ColumnChart> : null
                                        }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item4">
                    <div className="card blc_card">
                        <div className="card-body p-3">
                            <div className="mb-2 font-weight-600">
                                <p>Alerts that need your attention</p>
                            </div>
                            <div>
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    3
                                                </div>
                                            </td>
                                            <td className="border-top-0">New Monthly Financial datasets released</td>
                                            <td className="border-top-0"><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-danger text-white">
                                                    5
                                                </div>
                                            </td>
                                            <td>Data Exception(s) detected that has impacted the gener...</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    4
                                                </div>
                                            </td>
                                            <td>Company Financial(s) Overdue</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item5 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item6 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
                <div className="grid-item item7 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="row">
                <div className={panelVisibility ? 'col-md-9 pr-0' : 'col-12'}>
                    <div className="bg-dark h-13rem border-right dash-title">
                        <div className="container-fluid">
                            <div className="row pt-3">
                                <div className="col-10 dash-header-info d-flex">
                                    <div>
                                        <p>Total Invested</p>
                                        <h4>$ 234.9 M</h4>
                                    </div>
                                    <div>
                                        <p>Drawdown Available</p>
                                        <h4>$ 44.5 M</h4>
                                    </div>
                                    <div>
                                        <p>Fund Gross IRR</p>
                                        <h4>16.32 %</h4>
                                    </div>
                                    <div className="border-0">
                                        <p>LP Net IRR</p>
                                        <h4>11.23 %</h4>
                                    </div>
                                </div>
                                <div className="col-2 mob-hide">
                                    <div className="text-right">
                                        <i className="fa fa-sliders mr-3" aria-hidden="true"></i>
                                        <i className="fa fa-window-maximize transform-270 cp" aria-hidden="true" onClick={() => changePageContentSize()}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">


                        <div className="card-visibility font-weight-600">
                            <div className="row">
                                <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                    <div className="card mb-4 blc_card"><div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div><p className="mb-0">Total Valuation Req</p></div>
                                            <div className="icon-shape icon-md bg-light-primary text-primary rounded-2">
                                                <i className="fa fa-list-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className="fw-bold">55</h4>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                    <div className="card mb-4 blc_card"><div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <p className="mb-0">Not Started</p>
                                            </div>
                                            <div className="text-secondary">
                                                <i className="fa fa-history" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className="fw-bold">10</h4>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                    <div className="card mb-4 blc_card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <p className="mb-0">Pending Approvals</p>
                                                </div>
                                                <div className="text-warning">
                                                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className="fw-bold">30</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                    <div className="card mb-4 blc_card"><div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <p className="mb-0">Completed</p>
                                            </div>
                                            <div className="text-success">
                                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className="fw-bold">15</h4>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="mb-4">
                            {cardGridContainer()}
                        </section>
                        <section>
                            <div className="col-12 my-3 text-black p-0">
                                <h5 className="h5 mb-0 text-black font-weight-600">My Tasks</h5>
                            </div>
                            <div className="col-xl-12 col-md-12 p-0 mb-2">
                                <div className="form-row blc_button">
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>Category</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>All...</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>Submitted</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-md-12 p-0 mb-4 table-responsive font-small">
                                <table className="table table-sm" id="dataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th><input type={'checkbox'} /></th>
                                            <th>Company</th>
                                            <th>Latest Comments</th>
                                            <th></th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type={'checkbox'} /></td>
                                            <td className="dash-tab-comp">
                                                {/* <img src={c1Img} alt="c1" /> */}
                                                <p>XYZ Technologies Inc.</p>
                                                <p className="small">Request ID: VAL-005</p>
                                            </td>
                                            <td>The Valuation Team has submitted a valuation request</td>
                                            <td className="text-gray">Today</td>
                                            <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                                        </tr>
                                        <tr>
                                            <td><input type={'checkbox'} /></td>
                                            <td className="dash-tab-comp">
                                                {/* <img src={c2Img} alt="c2" /> */}
                                                <p>DEF Energy Corp</p>
                                                <p className="small">Request ID: VAL-008</p>
                                            </td>
                                            <td>Escalated to the CEO for final approval.</td>
                                            <td className="text-gray">2 days ago</td>
                                            <td><span className="badge badge-pill badge-secondary">IMMEDIATE</span></td>
                                        </tr>
                                        <tr>
                                            <td><input type={'checkbox'} /></td>
                                            <td className="dash-tab-comp">
                                                {/* <img src={c1Img} alt="c1" /> */}
                                                <p>XYZ Technologies Inc.</p>
                                                <p className="small">Request ID: VAL-009</p>
                                            </td>
                                            <td>Escalated to the CEO for final approval.</td>
                                            <td className="text-gray">2 days ago</td>
                                            <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>

                    </div>
                </div>
                {
                    panelVisibility ? <div className="col-xl-3 col-md-3 col-12 bg-gray">
                        <RightPanelComponent></RightPanelComponent>
                    </div> : null
                }
            </div>
        </div>
    )
}