import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";


const HeatmapChart = ({ data, yaxisData, xAxisData, height = '500px', dashHeat, yAxisVisiblity=true, xAxisVisiblity=true, categoryYField='weekday', categoryXField='hour', id='heatchartdiv' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let heatRoot = am5.Root.new(id);

        heatRoot.setThemes([
            am5themes_Animated.new(heatRoot)
        ]);
        heatRoot._logo.dispose();

        var chart = heatRoot.container.children.push(am5xy.XYChart.new(heatRoot, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: heatRoot.verticalLayout,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 0
        }));

        // Create axes and their renderers
        var yRenderer = am5xy.AxisRendererY.new(heatRoot, {
            visible: false,
            minGridDistance: 30,
            inversed: true
        });

        yRenderer.grid.template.set("visible", false);

        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(heatRoot, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: categoryYField,
            visible: yAxisVisiblity
        }));

        yAxis.get("renderer").labels.template.setAll({
            centerX: am5.p0,
            fontSize: 12
        });

        var xRenderer = am5xy.AxisRendererX.new(heatRoot, {
            visible: false,
            minGridDistance: 30,
            opposite: true
        });

        xRenderer.grid.template.set("visible", false);

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(heatRoot, {
            renderer: xRenderer,
            categoryField: categoryXField,
            oversizedBehavior: 'wrap',
            visible: xAxisVisiblity
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12
        });

        var series = chart.series.push(am5xy.ColumnSeries.new(heatRoot, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: categoryXField,
            categoryYField: categoryYField,
            valueField: "value"
        }));

        series.columns.template.setAll({
            tooltipText:
                `Company: {${categoryYField}}
Risk Score: {value}`,
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100),
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            fontSize: 12
        });

        series.columns.template.events.on("pointerover", function (event) {
            var di = event.target.dataItem;
            if (di) {
                // heatLegend.showValue(di.get("value", 0));
            }
        });

        series.columns.template.events.on('click', function (e) {
            dashHeat(e.target.dataItem.dataContext)
        })

        series.events.on("datavalidated", function () {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });

        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color("#edf6ff"),
            max: am5.color("#5eb0ef"),
            dataField: "value",
            key: "fill"
        }]);



        var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(heatRoot, {
            orientation: "horizontal",
            endColor: am5.color("#edf6ff"),
            startColor: am5.color("#5eb0ef"),
            visible: yAxisVisiblity,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            fontSize: 12
        }));


        series.data.setAll(data);


        yAxis.data.setAll(yaxisData);

        let xAxisItem = [
            { hour: "Jan" },
            { hour: "Feb" },
            { hour: "Mar" },
            { hour: "Apr" },
            { hour: "May" },
            { hour: "Jun" },
            { hour: "Jul" },
            { hour: "Aug" }
        ]

        xAxis.data.setAll(xAxisData ? xAxisData : xAxisItem);

        setChartRoot(heatRoot);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};

export default HeatmapChart;
